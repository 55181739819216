$white: #ffffff;
$black: #000000;
$offWhite: #F9F9F9;
$gray: #a2a2a2;
$dark-gray: #8B8B8B;
$light-gray: #d1d1d1;

$bg: #2E3192;

$primary-color: $white;
$secondary-color: #B04EC4;
$tertiary-color: #8DC63F;
$quaternary-color: $black;