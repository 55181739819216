body {
    background: $bg;

    #passwordResetForm {
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;

        .header-section {
            width: 100%;
            margin-top: 3rem;

            .header-inner {
                width: 100%;
                max-width: $global-width;
                margin: 0 auto;
                h4 {
                    background-color: $secondary-color;
                    display: inline;
                    margin-bottom: 0;
                    margin-top: .2rem;
                    padding: 1rem 5rem 0.8rem 1rem;
                }
            }
        }

        .entry-content {
            width: 100%;
            max-width: $global-width;
            margin: 0 auto;
            display:flex;
            flex-direction: row;
            justify-content: left;
            margin-top: 3rem;
            flex-wrap: wrap;

            .wpum-message.info {
                background: transparent;
                border: 0 none;
                color: $white;
            }

            .wpum-message {
                background-color: transparent;
                border: 0 none;
                border-radius: 0;
                box-shadow: none;
                text-shadow: none;
            }
            
            .wpmu-wrapper {
                width: 100%;

                .wpum-password-recovery-form {
                    display:flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin: 0 5rem;

                    #wpum-submit-password-recovery-form {
                        width: 100%;

                        input {
                            color: $white;
                            border: 0 none;
                            background: transparent;
                            border-bottom: 3px solid $white;
                            padding: 8px;
                            font-size: 15px;
                        }

                        input::-moz-placeholder,
                        input::-webkit-input-placeholder,
                        input:-ms-input-placeholder,
                        input:-moz-placeholder { 
                            color: $dark-gray;
                        }

                        input:-webkit-autofill,
                        input:-webkit-autofill:hover, 
                        input:-webkit-autofill:focus, 
                        input:-webkit-autofill:active{
                            border: 0 none;
                            border-bottom: 3px solid $white;
                            -webkit-text-fill-color: $white;
                            -webkit-box-shadow: none;
                            transition: background-color 5000s ease-in-out 0s;
                        }

                        input:hover {
                            color: $white;
                        }
                    
                        select {
                            background-color: $white;
                            color: $dark-gray;
                            border: 0 none;
                            border-bottom: 3px solid $white;

                            option {
                                background-color: transparent;
                            }
                        }

                        select:hover {
                            color: $dark-gray;
                        }

                        input.button {
                            border-bottom: 3px solid #B04EC4;
                            cursor: pointer;
                            font-family: $header-font-name;
                            font-size: 18px;
                            font-weight: 700;
                            margin: 0;
                            padding-bottom: 5px;
                        }
                    }
                }
            }

            .wpum-action-links {
                margin: 0 5rem;
                text-align: right;
                width: 100%;
            }
        }
    }
}