#wpum-account-forms-tabs ul li.active,
#wpum-account-forms-tabs ul li:hover {
    background: transparent;
}

.entry-content {
    width: 100%;
    max-width: $global-width;
    margin: 0 auto;
    display:flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 3rem;
    flex-wrap: wrap;

    .wpmu-wrapper {
        width: 100%;

        .wpum-account-form,
        .wpum-password-form {
            display:flex;
            flex-direction: row;
            flex-wrap: wrap;

            #wpum-submit-account-form,
            #wpum-submit-password-form {
                width: 100%;

                input {
                    color: $white;
                    border: 0 none;
                    background: transparent;
                    border-bottom: 3px solid $white;
                    padding: 8px;
                    font-size: 15px;
                }

                input::-moz-placeholder,
                input::-webkit-input-placeholder,
                input:-ms-input-placeholder,
                input:-moz-placeholder { 
                    color: $dark-gray;
                }

                input:-webkit-autofill,
                input:-webkit-autofill:hover, 
                input:-webkit-autofill:focus, 
                input:-webkit-autofill:active{
                    border: 0 none;
                    border-bottom: 3px solid $white;
                    -webkit-text-fill-color: $white;
                    -webkit-box-shadow: none;
                    transition: background-color 5000s ease-in-out 0s;
                }

                input:hover {
                    color: $white;
                }
            
                select {
                    background-color: $white;
                    color: $dark-gray;
                    border: 0 none;
                    border-bottom: 3px solid $white;

                    option {
                        background-color: transparent;
                    }
                }

                select:hover {
                    color: $dark-gray;
                }

                input.button {
                    border-bottom: 3px solid #B04EC4;
                    cursor: pointer;
                    font-family: $header-font-name;
                    font-size: 18px;
                    font-weight: 700;
                    margin: 0;
                    padding-bottom: 5px;
                }
            }
        }
    }
}