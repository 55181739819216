
.home {
  background: $bg;
  .wp-block-column {
      max-width: $global-width;
      margin: 0 auto;
  }
  .wp-block-columns {
      margin-bottom: 0;
  }

  #mainHead {
    .inner {
      padding: 0 .8rem;
    }
  }

  #homeForm {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;

    .header-section {
      width: 100%;
      margin-top: 3rem;

      .header-inner {
        width: 100%;
        max-width: $global-width;
        margin: 0 auto;

        h4 {
          background-color: $secondary-color;
          display: inline;
          margin-bottom: 0;
          margin-top: .2rem;
          padding: 1rem 1rem 0.8rem 1rem;

          @media screen and (max-width: 460px) {
            font-size: var(--fluid-h5);
          }

          @media screen and (max-width: 400px) {
            font-size: var(--fluid-h6);
          }

          @media screen and (max-width: 340px) {
            font-size: 18px;
          }
        }
      }
    }

    .main-section {
      width: 100%;
      max-width: $global-width;
      margin: 0 auto;
      display:flex;
      flex-direction: row;
      justify-content: left;
      margin-top: 3rem;
      flex-wrap: wrap;

      .gform_wrapper {
        width: 100%;

        .gform_page_fields {
          margin: 0 5rem;

          @media screen and (max-width: 460px) {
            margin: 0 1rem;
          }

          a {
            font-weight: bold;
          }
        }
      }

      input {
        color: $white;
        border: 0 none;
        background: transparent;
      }

      select {
        background-color: $white;
        color: $dark-gray;
        border: 0 none;
      }

      #gform_wrapper .gform_fields .gfield input::-moz-placeholder,
      #gform_wrapper .gform_fields .gfield input::-webkit-input-placeholder,
      #gform_wrapper .gform_fields .gfield input:-ms-input-placeholder,
      #gform_wrapper .gform_fields .gfield input:-moz-placeholder { 
        color: $dark-gray;
      }

      .gform_wrapper .gform_validation_errors {
        border: 0 none;
        border-radius: 0;
        box-shadow: none;
      }

      .gform_wrapper .gfield_validation_message, 
      .gform_wrapper .validation_message {
        display: none;
        border: 0 none;
        background-color: transparent;
      }

      .gform_wrapper.gravity-theme {
        .gfield input,
        .gfield select {
          border-bottom: 3px solid $white;

          option {
            background-color: transparent;
          }
        }

        .gfield input:hover {
          color: $white;
        }
  
        .gfield select:hover {
          color: $dark-gray;
        }

        .ginput_complex {
          span {
            /*padding-right: 10%;*/ 

            label {
              font-weight: 700;
            }
          }
        }

        .gfield_required_text{
          display: none;
        }

        .gsection {
          border: 0 none;
          margin-left: -5rem;

          @media screen and (max-width: 850px) {
            margin-left: -2rem;
          }

          @media screen and (max-width: 460px) {
            margin-left: -0.25rem;
          }
        }

        .gpfup__file-actions {
          .gpfup__delete {
            svg {
              color: red;
            }
          }
        }

        .gform_page_footer {
          display: flex;
          justify-content: center;
          margin: 4rem 1rem 0 1rem;

          @media screen and (max-width: 460px) {
            margin: 4rem .4rem 0 .4rem;
          }

          .gf_page_steps {
            border: 0 none;
            margin: 0 auto;
            position: relative;
            top: 14 px;
          }
          .gf_step {
            margin: 8px 0;
          }

          .gf_step_number {
            background: #FFFFFF;
            border: 0 none;
            font-size: 0;
            height: 12px;
            width: 12px;
          }

          .gf_step_active .gf_step_number,
          .gf_step_completed .gf_step_number {
            background: #8DC63F;
          }

          .gf_step_completed .gf_step_number::before {
            display: none;
          }

          .gf_step_completed .gf_step_number::after {
            display: none;
            font-size: 0;
          }

          .gform_button,
          .gform_previous_button,
          .gform_next_button {
            border-bottom: 3px solid #B04EC4;
            cursor: pointer;
            font-family: $header-font-name;
            font-size: 18px;
            font-weight: 700;
            margin: 0;
            padding-bottom: 0;

            &:hover {
              color: #B04EC4;
              border-bottom-color: transparent;
            }
          }

          .gform_button[type="submit"] {
            color: #B04EC4;
            border-bottom-color: transparent;

            &:hover {
              color: $white;
              border-bottom-color: #B04EC4;
            }
          }

          img.icon {
            height: 14.5px;
            width: 8.8px;
          }

          img.icon-back {
            margin: .4rem .6rem 0 0;
          }

          img.icon-next {
            margin: .4rem 0 0 .6rem;
          }
        }
      }

      .gform_wrapper .form-section h3 {
        font-size: var(--fluid-h5);

        @media screen and (max-width: 460px) {
          font-size: var(--fluid-h6);
        }

        @media screen and (max-width: 340px) {
          font-size: 16px;
        }
      }

      .gform_confirmation_message {
        padding: 1rem;
      }
    }

  }
}

#hero {
  display: flex;
  background: $primary-color;
}

*:focus-visible {
  outline: 0 none;
}