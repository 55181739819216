#mainHead {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    .inner {
        width: 100%;
        max-width: $global-width;
        margin: 0 auto;
        display:flex;
        flex-direction: row;
        justify-content: left;
        margin-top: 3rem;
        flex-wrap: wrap;

        @include breakpoint(large) {
            flex-wrap: nowrap;
        }

        #logo {
            img {
                width: 100%;
                max-width: 295px;
            }
        }
    }
}